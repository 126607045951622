import jwt_decode from 'jwt-decode';
import { API_HOST } from "../utils/constant";

export function getUser(token) {
  return jwt_decode(token);
}

export function createPaymentStripe(amount) {
  const url = `${API_HOST}/payments/stripe`;

  const params = {
    method: "POST",
    redirect: 'follow',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(amount),
  };

  return fetch(url, params)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.json();
      }
      return { message: "Usuario o contraseña incorrectos." };
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err;
    });
}

export function createPayment(payment, jwt) {
  const url = `${API_HOST}/payments`;

  const params = {
    method: "POST",
    redirect: 'follow',
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`
    },
    body: JSON.stringify(payment),
  };

  return fetch(url, params)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.json();
      }
      return { message: "Usuario o contraseña incorrectos." };
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err;
    });
}