import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import { createPaymentStripe } from "api/sicrux";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe("pk_test_51Iq0KQHiRHHXpphcgOl8clHsH2vPYziForwzuC1UiTxeEmBKJrjotrX7IA12G2stDeRlk9IReauBPfPKP7ZVjdNt00LAst3noq");

export type StripeTypes = {
   clientSecret: string;
   appearance: {
      theme: string,
      variables: {
         colorPrimary: string
      }
   }
};

export default function CreditComp() {
   const [clientSecret, setClientSecret] = useState("");
   const [amount, setAmount] = useState(0.0);

   useEffect(() => {
      createPaymentStripe({'amount': 10, 'currency': 'USD'}).then((data:any) => setClientSecret(data.clientSecret))
   }, [amount]);

   const appearance = {
      theme: 'stripe',
      variables: {
         colorPrimary: '#008b8b'
      }
   };
   const options: StripeTypes = {
      clientSecret,
      appearance,
   };

   return (
      <>
         {clientSecret && (
            <Elements stripe={stripePromise}>
               <CheckoutForm setAmount={setAmount} />
            </Elements>
         )}
      </>
   );
}