import Web3 from 'web3';
import { AbiItem } from 'web3-utils'
import { DevNetwork } from '../blockchain/chainParams';
import { useWeb3React } from "@web3-react/core"

import TradingAbi from '../blockchain/abi/trading.json';
import TokenAbi from '../blockchain/abi/BUSDAbi.json';
import { Application } from '../types/application';

const provider = (window as any).ethereum || (window as any).web3?.currentProvider;
const web3 = new Web3(provider);

const tradingContract = new web3.eth.Contract(
   TradingAbi as AbiItem[],
   DevNetwork.tradingAddress
).methods;

export const chainAPI = {
   balanceOf: async (token: string, account: string): Promise<any> => {
      const tokenContract = new web3.eth.Contract(
         TokenAbi as AbiItem[],
         token
      ).methods;
      const res: any = await tokenContract.balanceOf(account).call();
      return Web3.utils.fromWei(res.toString(), 'ether');
   },
   deposit: async (token: string, account: string, id: string, amount: string): Promise<any> => {
      const res: any = await tradingContract.deposit(Web3.utils.toWei(amount.toString(), 'ether'), token, id).send({ from: account });
      return res;
   },
   approveToken: async (token: string, account: string): Promise<any> => {
      const tokenContract = new web3.eth.Contract(
         TokenAbi as AbiItem[],
         token
      ).methods;
      const res: any = await tokenContract.approve(DevNetwork.tradingAddress, '0xFFFFFFFFFFFFFFFFFFFFF').send({ from: account });
      return res;
   },
   allowance: async (token: string, account: string): Promise<any> => {
      const tokenContract = new web3.eth.Contract(
         TokenAbi as AbiItem[],
         token
      ).methods;
      const res: any = await tokenContract.allowance(account, DevNetwork.tradingAddress).call();
      return res;
   },
   transfer: async (token: string, account: string, amount: string): Promise<any> => {
      const tokenContract = new web3.eth.Contract(
         TokenAbi as AbiItem[],
         token
      ).methods;
      const res: any = await tokenContract.transfer('0x19229b28ea4aefb4E77346B5313Fabe0e6168dFb', Web3.utils.toWei(amount.toString(), 'ether')).send({ from: account });
      return res;
   },
   //  fetchInverterDataBetweenDates: async (fromDate: number, toDate: number): Promise<InverterData[]> => {
   //      return await energySolarContract.getInverterDataBetweenDates("0xCEf095b6Ea31dbDd564515AEB0bDf979026Cc733", fromDate, toDate).call();
   //  },
};