import { getUser } from '../../api/sicrux';
import { chainAPI } from '../../api/blockchain';
import { 
    APPLICATION_SUCCESS,
    APPLICATION_PENDING,
    APPLICATION_ERROR,
    TOGGLE_SIDEBAR,
    APPROVE_SUCCESS,
    PAY_SUCCESS,
    PAY_PENDING,
    PAY_ERROR
} from './actionTypes';
import { Application } from '../../types/application';
import { Sidebar } from '../../types/sidebar';
import { Approve } from '../../types/approve';
import { ActionTypes } from './types'

export const setApplication = (application: Application): ActionTypes => ({
    type: APPLICATION_SUCCESS,
    application
});

export const setToggleSidebar = (sidebar: Sidebar): ActionTypes => ({
    type: TOGGLE_SIDEBAR,
    sidebar
});

export const setPending = (): ActionTypes => ({
    type: APPLICATION_PENDING,
});

export const setError = (): ActionTypes => ({
    type: APPLICATION_ERROR,
});

export const setApproveSuccess = (approve: Approve): ActionTypes => ({
    type: APPROVE_SUCCESS,
    approve
});

export const getUserData = (token: string) => async (dispatch: any) => {
    try {
        dispatch(setPending());
        const user: any = await getUser(token)
        dispatch(setApplication({account: '', userID: user._id, username: user.userName, email: user.email, jwt: token}));
    } catch (error) {
        console.error(error);
        dispatch(setError());
    }
};

export const getApprovedToken = (token: string, account: string) => async (dispatch: any) => {
    try {
        const res = await chainAPI.approveToken(token, account);
        dispatch(setApproveSuccess({txHash: res.transactionHash}));
        console.log(res.transactionHash)
        return res
    } catch (error) {
        dispatch(setError());
    }
};

export const getToggleSidebar = (sidebar: Sidebar) => async (dispatch: any) => {
    dispatch(setToggleSidebar(sidebar));
};