import Crypto from 'views/crypto';
import Credit from 'views/credit';


import i18n from 'i18n'


const routes = [
	{
		name: i18n.t('order-sidebar'),
		layout: '/admin',
		path: '/crypto',
		component: Crypto
	},
	{
		name: i18n.t('history-sidebar'),
		layout: '/admin',
		path: '/credit',
		component: Credit
	}
];

export default routes;