import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import { Route, Switch, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { Provider } from "react-redux";
import { ChakraProvider } from '@chakra-ui/react';
import { Web3ReactProvider } from '@web3-react/core';
import Web3 from 'web3'

import DashboardLayout from './layouts/dashboard';
import theme from './theme/theme';
import store from "./redux/store";

import './assets/css/App.css';
import 'react-toastify/dist/ReactToastify.css';

function getLibrary(provider: any) {
	return new Web3(provider)
}

ReactDOM.render(
	<Web3ReactProvider getLibrary={getLibrary}>
		<ChakraProvider theme={theme}>
			<Provider store={store}>
				<React.StrictMode>
					<ToastContainer position='top-right' autoClose={5000} hideProgressBar newestOnTop={false} closeOnClick rtl={false} limit={1} />
					<Router basename={process.env.PUBLIC_URL}>
						<Switch>
							<Route path={`/crypto`} component={DashboardLayout} />
							<Route path={`/credit`} component={DashboardLayout} />
							<Redirect from='/' to='/crypto' />
						</Switch>
					</Router>
				</React.StrictMode>
			</Provider>
		</ChakraProvider>,
	</Web3ReactProvider>,
	document.getElementById('root')
);
