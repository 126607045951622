/* eslint-disable */
// Chakra Imports
import { Box, Icon, Flex, Link, Image, useColorModeValue } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { FiMenu } from 'react-icons/fi';
import { useAppDispatch, useAppSelector } from "../../hooks";

import { setToggleSidebar } from "../../redux/reducers/actions";
import AdminNavbarLinks from 'components/navbar/NavbarLinksAdmin';

import logo from '../../assets/img/logo/logo.png'

export default function AdminNavbar(props: {
	secondary: boolean;
	message: string|boolean;
	brandText: string;
	logoText: string;
	fixed: boolean;
	onOpen: (...args: any[]) => any;
}) {
	const [ scrolled, setScrolled ] = useState(false);
	const dispatch = useAppDispatch();
	const { sidebar } = useAppSelector((state: any) => state.sidebar);

	useEffect(() => {
		window.addEventListener('scroll', changeNavbar);

		return () => {
			window.removeEventListener('scroll', changeNavbar);
		};
	});

	const { secondary,  brandText } = props;

	// Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
	let mainText = useColorModeValue('navy.700', 'white');
	let paddingX = '15px';
	let gap = '0px';
	const changeNavbar = () => {
		if (window.scrollY > 1) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	};

	return (
		<Box
			position='fixed'
			top='0px'
			className='navbar__sicrux'
			h='4rem'
			left={ sidebar.show ? '280px' : '0px' }
			transitionDelay='0s, 0s, 0s, 0s'
			transitionDuration=' 0.25s, 0.25s, 0.25s, 0s'
			transition-property='box-shadow, background-color, filter, border'
			transitionTimingFunction='linear, linear, linear, linear'
			alignItems={{ xl: 'center' }}
			display={secondary ? 'block' : 'flex'}
			minH='70px'
			justifyContent={{ xl: 'center' }}
			lineHeight='25.6px'
			mx='auto'
			pb='8px'
			px={{
				sm: paddingX,
				md: '10px'
			}}
			ps={{
				xl: '12px'
			}}
			w={{
				base: 'calc(100vw - 6%)',
				md: 'calc(100vw - 8%)',
				lg: sidebar.show ? 'calc( 100% - 280px )' : '100%'
			}}>
			<Flex
				w='100%'
				flexDirection={{
					sm: 'column',
					md: 'row'
				}}
				alignItems={{ xl: 'center' }}
				mb={gap}>
				<Flex mb={{ sm: '8px', md: '0px' }}>
					{!sidebar.show && <Image w='10rem' src={logo} />}
					<Link
						color={mainText}
						href='#'
						bg='inherit'
						borderRadius='inherit'
						fontWeight='bold'
						fontSize='34px'
						_hover={{ color: { mainText } }}
						_active={{
							bg: 'inherit',
							transform: 'none',
							borderColor: 'transparent'
						}}
						_focus={{
							boxShadow: 'none'
						}}>
						{/* {brandText} */}
					</Link>
				</Flex>
				<Box ms='auto' w={{ sm: '100%', md: 'unset' }}>
					<AdminNavbarLinks
						onOpen={props.onOpen} 
						secondary={props.secondary}
						fixed={props.fixed} 
					/>
				</Box>
			</Flex> 
		</Box>
	);
}
