import { 
    APPLICATION_SUCCESS,
    APPLICATION_PENDING,
    APPLICATION_ERROR
} from './actionTypes';
import { ActionTypes, ApplicationState } from './types'

const initialState: ApplicationState = {
    application: {account: '', userID: '', username: '', email: '', jwt: ''},
    pending: false,
    error: false
};

export default function(state = initialState, action: ActionTypes): ApplicationState {
    switch (action.type) {
        case(APPLICATION_SUCCESS): {
            return {
                ...state,
                application: action.application
            }
        }
        default:
            return state;
    }
};
