import { useEffect } from 'react'
import { Flex } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import CryptoComp from 'views/crypto/components/Crypto';
import { getUserData } from "../../redux/reducers/actions";
import { useAppDispatch } from "../../hooks";



export default function Checkout() {
	const location = useLocation()
	const params = new URLSearchParams(location.search)
	const dispatch = useAppDispatch();

	const handleConnect = async () => {
		const userData = await getUserData(params.get('token'));
		dispatch(userData);
	};

	useEffect(() => {
		handleConnect();
	}, []);

	return (
		<Flex flexDirection='column' justifyContent='center' alignItems='center' pt={{ base: '130px', md: '100px', xl: '120px' }}>
			<CryptoComp />
		</Flex>
	);
}
