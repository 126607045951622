import React, { useState, useEffect } from 'react';
import {
   Box, Flex, Text, Button, Spinner, useColorModeValue, Icon, Modal,
   ModalOverlay,
   ModalContent,
   ModalHeader,
   ModalFooter,
   ModalBody,
   useDisclosure,
   ModalCloseButton
} from '@chakra-ui/react';

import i18n from '../../../i18n'
import { FaExternalLinkAlt } from 'react-icons/fa';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { RxCrossCircled } from 'react-icons/rx';
import { chainAPI } from 'api/blockchain';
import { createPayment } from 'api/sicrux';
import { DASHBOARD_HOST } from '../../../utils/constant';

export default function PayModal(props: { [x: string]: any }) {
   const { ...rest } = props;
   const tagsColor = useColorModeValue('white', 'white');
   const { isOpen, onOpen, onClose } = useDisclosure()
   const [txHash, setTxHash] = useState('');
   const [transactionError, setTransactionError] = useState(false);

   const handleClickPay = async () => {
      onOpen()
      setTransactionError(false)
      //const res = await chainAPI.deposit(rest.account, '63f6c58d37681c1e21e23', rest.tokenToAdd, rest.tokenAmount);
      await chainAPI.transfer(rest.tokenToAdd, rest.application.account, rest.tokenAmount).then((res) => {
         console.log(res)
         if (res.status) {
            setTxHash(res.transactionHash)
            createPayment({ customer: rest.application.userID, txHash: res.transactionHash, token: rest.tokenToAdd, amount: rest.tokenAmount, currency: 'crypto' }, rest.application.jwt)
         }
      }).catch(() => setTransactionError(true));
   }

   const handleOnClose = () => {
      onClose()
      transactionError ? setTransactionError(false) : txHash !== '' ? window.location.href = DASHBOARD_HOST + "/admin/dashboard" : setTxHash('')
   }

   return (
      <>
         <Button disabled={rest.disabled} bg='rgb(49, 51, 56)' onClick={handleClickPay} w='30%' borderRadius='10px'>{i18n.t('checkout-send-btn')}</Button>

         <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={handleOnClose}>

            <ModalOverlay />
            <ModalContent bg='#190a11'>
               <ModalHeader fontSize='sm'>&nbsp;</ModalHeader>
               <ModalCloseButton />
               <ModalBody p={4}>
                  <Flex p='0px 20px' justify='space-between' flexDirection='column'>

                     {(txHash === '' && !transactionError) && <Text fontWeight='bold' color={tagsColor}>{i18n.t('modal-pay-text')}</Text>}
                     <Flex justifyContent='center' p={txHash === '' ? '5vh' : '1vh'}>
                        {transactionError ? <Icon as={RxCrossCircled} color='red' w={40} h={40} /> : (txHash === '' ? <Spinner size='xl' /> : <Icon as={IoCheckmarkCircle} color='green' w={40} h={40} />)}
                     </Flex>
                     <Flex flexDirection='column' alignItems='center'>
                        {transactionError ? <Text>{i18n.t('modal-pay-error-text')}</Text>
                           : (txHash !== '' &&
                              <>
                                 <Text>{i18n.t('modal-amount-text')}: </Text>
                                 <Text fontWeight='bold' fontSize='lg'>$ {rest.tokenAmount}</Text>
                                 <Text mb='4vh' textAlign='center' fontWeight='bold' color={tagsColor}>{i18n.t('modal-pay-text-success')}</Text>
                                 <a className='external_link' target="_blank" rel="noreferrer" href={'https://bscscan.com/tx/' + txHash}><Text display='inline' fontWeight='bold'>{i18n.t('modal-view-explorer-text')}</Text> <Icon ml='5px' as={FaExternalLinkAlt} w={6} h={6} /></a>
                              </>
                           )
                        }
                        <Button onClick={handleOnClose} mt='10px'>{i18n.t('close-btn')}</Button>
                     </Flex>
                  </Flex>
               </ModalBody>
               <ModalFooter>
               </ModalFooter>
            </ModalContent>
         </Modal>
      </>


   );
}
