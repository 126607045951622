export enum NetworkEnum {
  MAINET = 'mainnet',
  TESTNET = 'testnet',
}

export const networks = (network: NetworkEnum) => {
  switch (network) {
    case NetworkEnum.MAINET:
      return ProdNetworks;
    case NetworkEnum.TESTNET:
      return DevNetwork;
  }
};

export const DevNetwork = 
  {
    index: 0,
    image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png',
    name: 'BSC Mainnet',
    contractNetworkName: 'bsc',
    tradingAddress: '0x2e2737aff1B47bAD0f72F2C04825D214EeC20922',
    busdContractAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    usdtContractAddress: '0x55d398326f99059fF775485246999027B3197955',
    usdcContractAddress: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    chainIdInt: 56,
    params: {
      chainId: '0x38',
      chainName: 'Binance Smart Chain Mainnet',
      nativeCurrency: {
        name: 'Binance Coin',
        symbol: 'BNB',
        decimals: 18,
      },
      rpcUrls: ['https://bsc-dataseed.binance.org/'],
      blockExplorerUrls: ['https://bscscan.com'],
    },
    acceptedPaymentTokens: [
      {
        symbol: 'BNB',
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png',
        address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      },
      {
        symbol: 'BUSD',
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png',
        address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      },
    ],
  }
  // {
  //   index: 0,
  //   image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png',
  //   name: 'BSC Testnet',
  //   contractNetworkName: 'bsc',
  //   tradingAddress: '0x0a8be3491e10879dc5B6712663A96676C532Ee86',
  //   busdContractAddress: '0x78867BbEeF44f2326bF8DDd1941a4439382EF2A7',
  //   usdtContractAddress: '0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814',
  //   usdcContractAddress: '0x5C97620326C043D9FEC41D88FE0cFAcFbc1E68F7',
  //   chainIdInt: 97,
  //   params: {
  //     chainId: '0x61',
  //     chainName: 'Binance Smart Chain Testnet',
  //     nativeCurrency: {
  //       name: 'Binance Coin',
  //       symbol: 'tBNB',
  //       decimals: 18,
  //     },
  //     rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
  //     blockExplorerUrls: ['https://testnet.bscscan.com'],
  //   }
  // }
;

const ProdNetworks: any[] = [];
