import { 
  TOGGLE_SIDEBAR
} from './actionTypes';
import { ActionTypes, SidebarState } from './types'

const initialState: SidebarState = {
  sidebar: {show: false}
};

export default function(state = initialState, action: ActionTypes): SidebarState {
  switch (action.type) {
    case TOGGLE_SIDEBAR: {
      return {
        ...state,
        sidebar: action.sidebar
      };
    }
    default:
      return state;
  }
};
