import { useState } from 'react';
import {
   Flex, Text, Image, Icon,
   Modal,
   ModalOverlay,
   ModalContent,
   ModalHeader,
   ModalFooter,
   ModalBody,
   ModalCloseButton,
   useDisclosure
} from '@chakra-ui/react';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { toast } from 'react-toastify';

import { DevNetwork } from '../../../blockchain/chainParams'
import i18n from '../../../i18n'

import busd from '../../../assets/img/select/busd.png'
import usdc from '../../../assets/img/select/usdc.png'
import usdt from '../../../assets/img/select/usdt.png'
import { chainAPI } from 'api/blockchain';


export default function CurrencyModal(props: { [x: string]: any }) {
   const { isOpen, onOpen, onClose } = useDisclosure()
   const [currency, setCurrency] = useState('');
   const [busdBalance, setBusdBalance] = useState(0);
   const [usdtBalance, setUsdtBalance] = useState(0);
   const [usdcBalance, setUsdcBalance] = useState(0);
   const [image, setImage] = useState('');

   const handleCheckCurrency = (t: string, name: string) => {
      let b: number;
      if (name === 'usdt') {
         setCurrency(name)
         setImage(usdt)
         b = usdtBalance
      } else if (name === 'busd') {
         setCurrency(name)
         setImage(busd)
         b = busdBalance
      }else if (name === 'usdc') {
         setCurrency(name)
         setImage(usdc)
         b = usdcBalance
      }
      props.getCurrency(t, Number(b))
      onClose()
   }

   const handleOnOpenModal = async () => {
      if (props.account) {
         onOpen()
         setUsdtBalance(await chainAPI.balanceOf(DevNetwork.usdtContractAddress, props.account))
         setBusdBalance(await chainAPI.balanceOf(DevNetwork.busdContractAddress, props.account))
         setUsdcBalance(await chainAPI.balanceOf(DevNetwork.usdcContractAddress, props.account))
      } else {
         toast.warning(i18n.t('checkout-alert-info'));
      }
      
   }

   return (
      <>
         <Flex cursor='pointer' justifyContent='space-between' alignItems='center' onClick={handleOnOpenModal}>
            {currency === '' ? <Text fontWeight='bold'>{i18n.t('checkout-select-btn')}</Text> : (
               <Flex alignItems='center'>
                  <Image h='35px' alt='usdt' src={image} mr='10px' />
                  <Text fontWeight='bold'>
                     {currency.toUpperCase()}
                  </Text>
               </Flex>
            )}
            <Icon boxSize={10} color='rgb(21,20,25)' as={MdOutlineKeyboardArrowDown} />
         </Flex>
         <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>

            <ModalOverlay />
            <ModalContent bg='#190a11'>
               <ModalHeader fontSize='sm'>Common tokens</ModalHeader>
               <ModalCloseButton />
               <ModalBody p={0}>
                  <Flex className='curency' cursor='pointer' onClick={() => handleCheckCurrency(DevNetwork.usdtContractAddress, 'usdt')} mt='15px' justifyContent='space-between' alignItems='center'>
                     <Flex>
                        <Image h='35px' alt='usdt' src={usdt} />
                        <Flex ml='10px' flexDirection='column'>
                           <Text fontWeight='bold'>USDT</Text>
                           <Text fontSize='smaller'>Tether USDT</Text>
                        </Flex>
                     </Flex>
                     <Text>{usdtBalance}</Text>
                  </Flex>
                  <Flex className='curency' cursor='pointer' onClick={() => handleCheckCurrency(DevNetwork.busdContractAddress, 'busd')} mt='15px' justifyContent='space-between' alignItems='center'>
                     <Flex>
                        <Image h='35px' alt='busd' src={busd} />
                        <Flex ml='10px' flexDirection='column'>
                           <Text fontWeight='bold'>BUSD</Text>
                           <Text fontSize='smaller'>Binance USD</Text>
                        </Flex>
                     </Flex>
                     <Text>{busdBalance}</Text>
                  </Flex>
                  <Flex className='curency' cursor='pointer' onClick={() => handleCheckCurrency(DevNetwork.usdcContractAddress, 'usdc')} mt='15px' justifyContent='space-between' alignItems='center'>
                     <Flex>
                        <Image h='35px' alt='usdc' src={usdc} />
                        <Flex ml='10px' flexDirection='column'>
                           <Text fontWeight='bold'>USDC</Text>
                           <Text fontSize='smaller'>USD Coin</Text>
                        </Flex>
                     </Flex>
                     <Text>{usdcBalance}</Text>
                  </Flex>
               </ModalBody>
               <ModalFooter>
               </ModalFooter>
            </ModalContent>
         </Modal>

      </>


   );
}
