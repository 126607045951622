import { useEffect } from 'react'
import {
	Button,
	Flex,
	useColorModeValue
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

// Custom Components
import PropTypes from 'prop-types';
// Assets
import { useWeb3React } from "@web3-react/core"
import { injected, switchRequest } from '../../blockchain/metamaskConnector';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { setApplication } from 'redux/reducers/actions';
import i18n from 'i18n';

export default function HeaderLinks(props: { secondary: boolean }) {
	const { secondary } = props;

	const { application } = useAppSelector((state: any) => state.application);
	const location = useLocation()
	const dispatch = useAppDispatch();

	// Chakra Color Mode
	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	);
	const { active, account, chainId, activate, deactivate } =
		useWeb3React();

	useEffect(() => {
		if (account) dispatch(setApplication({ ...application, account }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [account]);

	useEffect(() => {
		if (chainId !== 56 && account) switchRequest({chainIdHex: '0x38'})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chainId]);

	async function connectMetamaks() {
		try {
			await activate(injected, undefined, true);
			localStorage.setItem("connector", "injected")
			localStorage.setItem("isWalletConnected", 'true')
			
		} catch (ex) {
			console.log(ex);
		}
	}

	function getWalletAbreviation(walletAddress: string): string {
		return walletAddress.slice(0, 6) + "..." + walletAddress.slice(-4);
	}

	async function disconnect() {
		try {
			deactivate();
			localStorage.setItem("isWalletConnected", 'false');
			localStorage.removeItem('connector');
		} catch (ex) {
			console.log(ex);
		}
	}

	return (
		<Flex
			w={{ sm: '100%', md: 'auto' }}
			alignItems='center'
			flexDirection='row'
			flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
			p='10px'
			borderRadius='30px'
			boxShadow={shadow}>

			{location.pathname === '/crypto' && <Button colorScheme={active ? 'green' : 'blue'}
				onClick={active ? disconnect : connectMetamaks}>
				{account ? chainId === 56 ? <span><b>{getWalletAbreviation(account)}</b></span> : <span><b>{i18n.t('wrong-network-text')}</b></span> : <span>{i18n.t('wallet-text')}</span>}

			</Button>}
		</Flex>
	);
}

HeaderLinks.propTypes = {
	variant: PropTypes.string,
	fixed: PropTypes.bool,
	secondary: PropTypes.bool,
	onOpen: PropTypes.func
};
