import React, { useState, useEffect } from 'react';
import {
	Box, Flex, Text, Input, Button, useColorModeValue
} from '@chakra-ui/react';

import i18n from '../../../i18n'

import Card from 'components/card/Card';
import ApproveButton from './ApproveModal';
import CurrencyButton from './CurrencyModal';
import PayButton from './PayModal';
import { useAppSelector } from '../../../hooks';
import { chainAPI } from 'api/blockchain';
import { useWeb3React } from '@web3-react/core';


export default function CryptoComp(props: { [x: string]: any }) {
	const { ...rest } = props;
	const { application } = useAppSelector((state: any) => state.application);
	const { approve } = useAppSelector((state: any) => state.approve);
	const { account } = useWeb3React();

	const [allowance, setAllowance] = useState(1);
	const [tokenToAdd, setTokenToAdd] = useState({ token: '', balance: 0 });
	const [tokenAmount, setTokenAmount] = useState(0);

	useEffect(() => {
		if (account && tokenToAdd.token != '') getAllowance().then((r) => {
			setAllowance(r)
		})
	}, [account, tokenToAdd]);

	const getAllowance = async () => {
		return await chainAPI.allowance(tokenToAdd.token, account);
	}

	const getCurrency = (t: string, b: number) => {
		setTokenToAdd({ token: t, balance: b })
	}

	const handleOnChangeInput = (v: any) => {
		if (!isNaN(v)) setTokenAmount(v)
	}

	const handleClickButtonAmount = (p: number) => {
		setTokenAmount(Number(((tokenToAdd.balance * p) / 100).toFixed(2)))
	}


	return (
		<>
			<Card className='crypto_card' p='20px' mt='30px' bg='rgb(21,20,25)' alignItems='center' flexDirection='column' {...rest}>
				<Box px='6px' w='100%'>
					<Flex w='100%' justify='space-between' flexDirection='column'>

						<Card justifyContent='space-between' backgroundColor='rgb(49, 51, 56)' p='10px 25px' mt='16px' flexDirection='column' w='100%' {...rest}>
							<CurrencyButton account={account} getCurrency={getCurrency} />
						</Card>

						<Card justifyContent='space-between' backgroundColor='rgb(49, 51, 56)' p='7px 25px' mt='16px' flexDirection='column' w='100%' {...rest}>
							<Input
								textAlign='end'
								border='none'
								id='amount'
								name='amount'
								value={tokenAmount}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChangeInput(e.target.value)}
								isRequired={true}
								variant="auth"
								fontSize="sm"
								borderRadius='10px'
								ms={{ base: "0px", md: "0px" }}
								type="text"
								fontWeight="500"
								size="lg"
							/>
							{tokenToAdd.token !== '' && (
								<>
									<Flex justifyContent='end'>
										<Text>{tokenToAdd.balance > 0 && tokenToAdd.balance.toFixed(2)}</Text>
									</Flex>
									<Flex justifyContent='end'>
										<Button mr='5px' h='2rem' onClick={() => handleClickButtonAmount(25)}>25%</Button>
										<Button mr='5px' h='2rem' onClick={() => handleClickButtonAmount(50)}>50%</Button>
										<Button mr='5px' h='2rem' onClick={() => handleClickButtonAmount(75)}>75%</Button>
										<Button h='2rem' onClick={() => handleClickButtonAmount(100)}>MAX</Button>
									</Flex>
								</>

							)}
						</Card>

						<Flex mt='20px' justifyContent='space-around'>
							{/* {allowance == 0 && <ApproveButton setAllowance={setAllowance} token={tokenToAdd.token} account={account} />} */}
							<PayButton disabled={(tokenToAdd.token === '' || tokenAmount === 0)} application={application} tokenToAdd={tokenToAdd.token} tokenAmount={tokenAmount} />
						</Flex>

					</Flex>
				</Box>
			</Card>
		</>
	);
}
