import { 
  APPROVE_SUCCESS
} from './actionTypes';
import { ActionTypes, ApproveState } from './types'

const initialState: ApproveState = {
  txHash: ''
};

export default function(state = initialState, action: ActionTypes): ApproveState {
  switch (action.type) {
    case APPROVE_SUCCESS: {
      return {
        ...state,
        txHash: action.approve.txHash
      };
    }
    default:
      return state;
  }
};
