export const APPLICATION_SUCCESS = "APPLICATION_SUCCESS";
export const APPLICATION_PENDING = "APPLICATION_PENDING";
export const APPLICATION_ERROR = "APPLICATION_ERROR";

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";

export const APPROVE_SUCCESS = "APPROVE_SUCCESS";

export const PAY_SUCCESS = "PAY_SUCCESS";
export const PAY_PENDING = "PAY_PENDING";
export const PAY_ERROR = "PAY_ERROR";